import React, { useState, useEffect } from "react";

interface Location {
  loaded: boolean;
  coordinates: {
    lat: string;
    lng: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

const useGeoLocation = (): Location => {
  const [location, setLocation] = useState<Location>({
    loaded: false,
    coordinates : {
      lat: "0.0",
      lng: "0.0"
    }
  
  });

  const onSuccess = (location: GeolocationPosition) => {
  
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude.toString(),
        lng: location.coords.longitude.toString(),
      },
    });
  };

  const onError = (error: GeolocationPositionError) => {
    setLocation({
      loaded: true,
      coordinates : {
        lat: "0.0",
        lng: "0.0"
      },
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
     
    }
  }

useEffect(()=>{
  handleLocationClick()
},[])

  return  location;
};

export default useGeoLocation;
