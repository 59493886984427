import React from "react";
import "./pie.scss"
const cleanPercentage = (percentage: number): number => {
  const tooLow = !Number.isFinite(percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : percentage;
};

interface CircleProps {
  colour: string;
  pct?: number;
}

const Circle: React.FC<CircleProps> = ({ colour, pct = 0 }) => {
  const r = 40;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;

  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ  && pct !== 0 ? colour : "lightgray"}
      strokeWidth={"0.5rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

interface TextProps {
  percentage: number;
}

const Text: React.FC<TextProps> = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"16px"}
      fontFamily="var(--Roboto)"
    >
      {percentage.toFixed(0)}%
    </text>
  );
};

interface PieProps {
  percentage: number;
  colour: string;
}

const Pie: React.FC<PieProps> = ({ percentage, colour }) => {
  const pct = cleanPercentage(percentage);
  
  return (
  
    <svg width={130} height={130}>
    <g transform={`rotate(-95 ${"67 99"})`}>
      <Circle colour="lightgrey" />
      <Circle colour={colour} pct={pct} />
    </g>
    <Text percentage={pct} />
  </svg>
  );
};

export default Pie;
