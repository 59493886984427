export class PageRoutes {
  static home = "/";
  static aboutUs = "/who";
  static WorkFromAnywhere = "/where";
  static ticcaProduct = "/what";
  static innovation = "/how";
  static ourtrust = "/ourtrust";
  static ourflexibility = "/ourflexibility";
  static ourcommunication = "/ourcommunication&collbaboration";
  static ourculture = "/ourculture";
  static ourdiversity = "/ourdiversity";
  static OurEquality = "/OurEqualityInclusion";
  static TICCAValues = "/TiccaValues";
  static ShapingtheFutureofWork = "/ShapingtheFutureofWork";
  static diversityInclusion = "/diversityInclusion";
  static SignUp = "/signup";
  static VerifyEmail = "/VerifyEmail";
  static VerifyPhone = "/verifyPhone";
  static Download = "/Download";
  static TiccaNewEnvironment = "/TiccaNewEnvironment";
  static Ownership = "/Ownership";
  static signIn = "/signin";
  static TermsCondition = "/TermsCondition";
  static Login = "/Login";
  static Emailverification = "/FrogetPassword";
  static FAVerify = "/FAVerify";
  static Remember = "/Remember";
  static ChangePassword = "/ChangePassword";
  static VerifyPassword = "/VerifyPasswordEmail";
  static InvestorCenter = "/investor";
  static PPMpresentation ="/PPMpresentation/:id";
  static PPMforwardlooking = "/investor/forward-looking-document";
  static ExecutiveSummary = "/investor/business-summary";
  static InvestmentOverview = "/investor/investment-overview";
  static ProductOverview  ="/investor/product-overview";
  static TableContent = "/ppm/table-of-content";
  static Prospectus ="/ppm";
  static LoginToken  = "/login/verify";
  static WhyChooseUs ="/why";
  static ContactUs ="/contact";
  static When = "/when"
  static Intro = "/investor/intro";
  static question = "/investor/question";
  static qa  ="/Q&A"
}
