

export const jsonToFrom = (values: any) => {
  let formData = new FormData();

  const appendFormData = (data: any, parentKey: string | null = null) => {
    if (data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;
        appendFormData(data[key], fullKey);
      });
    } else {
      if (parentKey) {
        formData.append(parentKey, data);
      }
    }
  };

  appendFormData(values);

  return formData;
};

