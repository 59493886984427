import React, { useEffect, useState } from "react";
import {
  GetTalkToOurFounder,
  GetcmsContent,
  fetIpAddress,
} from "../../utils/Api/api";
import parser from "html-react-parser";
import "./qa.scss";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import { Link, Element, scroller } from "react-scroll";
import { InView } from "react-intersection-observer";
import { Button } from "@mui/material";

const Qa = () => {
  const [topicsToogle, setTopicsToogle] = useState(false);
  const [topicText, setTopicText] = useState("GENERAL");
  const [talkToFounder, setTalkToFounder] = useState(false);
  
  const topics = [
    "GENERAL",
    "FOUNDERS",
    "TEAM",
    "CAP TABLE",
    "EXIT",
    "FINANCE & METRICS",
    "MARKET & COMPETITION",
    "HISTORY & STRUCTURE",
  ];
  const loaction = useGeoLocation();
  const [htmlString, setHtmlStr] = useState([]);
  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };
  const cmsContent = async () => {
    try {
      const response1 = await GetcmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "9431",
      });

      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {}
  };
  const scrollToSection = (index: number) => {
    const element = document.getElementById(`btn-${index}`);
    if (element) {
      const offset = 250; // The offset in pixels
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // This enables smooth scrolling
      });
    }
  };
  const getTalk = async () => {
    try {
      const response = await GetTalkToOurFounder({
        ip_address: await fetchIpAddress(),
        latitude: await loaction.coordinates.lat,
        longitude: await loaction.coordinates.lng,
      });
      if (response.data.status == 200) {
        setTalkToFounder(response?.data?.status_code == 1)
      }
    } catch (error) {}
  };
  useEffect(() => {
    document.title = "TICCA | Q&A";
    cmsContent();
    getTalk()
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="insights-ir-team-main-dev">
        <div className="insights-ir-team-dev">
          <div className="insights-it-title-dev">
            <h1>Insights from our IR Team</h1>
            <p>Q&A for Investors</p>
            { talkToFounder && <Button
           
            className="btn-grow-market"
            type="submit"
          >
            Talk to our Founder{" "}
          </Button> }
          </div>
          <div className="search-dev">
            <samp className="search-selected-icon">
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3407 3.97345H18.7504V5.36234H20.1393V22.7235H4.86155V5.36234H6.25043V3.97345H4.86155C4.69148 3.94886 4.51813 3.9606 4.35294 4.00792C4.18774 4.05524 4.03446 4.13705 3.90321 4.24796C3.77196 4.35887 3.66572 4.49635 3.5915 4.65133C3.51728 4.80632 3.47678 4.97528 3.47266 5.14706V22.9387C3.47357 23.0938 3.505 23.2471 3.56517 23.39C3.62534 23.5329 3.71307 23.6625 3.82334 23.7715C3.93361 23.8805 4.06426 23.9667 4.20785 24.0251C4.35143 24.0836 4.50512 24.1133 4.66016 24.1123H20.3407C20.4957 24.1133 20.6494 24.0836 20.793 24.0251C20.9366 23.9667 21.0673 23.8805 21.1775 23.7715C21.2878 23.6625 21.3755 23.5329 21.4357 23.39C21.4959 23.2471 21.5273 23.0938 21.5282 22.9387V5.14706C21.5273 4.99203 21.4959 4.83869 21.4357 4.69581C21.3755 4.55293 21.2878 4.42329 21.1775 4.31431C21.0673 4.20533 20.9366 4.11914 20.793 4.06065C20.6494 4.00217 20.4957 3.97254 20.3407 3.97345Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M18.0564 5.58965C18.0546 5.16108 17.8835 4.75058 17.5805 4.44753C17.2774 4.14448 16.8669 3.97342 16.4384 3.97159H15.1884C15.0354 3.37408 14.6879 2.84448 14.2007 2.46629C13.7134 2.08809 13.1142 1.88281 12.4974 1.88281C11.8806 1.88281 11.2814 2.08809 10.7941 2.46629C10.3069 2.84448 9.95941 3.37408 9.80642 3.97159H8.56337C8.1348 3.97342 7.7243 4.14448 7.42125 4.44753C7.1182 4.75058 6.94714 5.16108 6.94531 5.58965V8.13826H18.0564V5.58965ZM16.6675 6.74937H8.3342V5.58965C8.3342 5.55955 8.34013 5.52975 8.35165 5.50195C8.36316 5.47415 8.38004 5.44888 8.40132 5.4276C8.4226 5.40632 8.44787 5.38944 8.47567 5.37793C8.50347 5.36641 8.53327 5.36048 8.56337 5.36048H11.112V4.66604C11.112 4.29768 11.2583 3.94441 11.5188 3.68394C11.7792 3.42348 12.1325 3.27715 12.5009 3.27715C12.8692 3.27715 13.2225 3.42348 13.483 3.68394C13.7434 3.94441 13.8898 4.29768 13.8898 4.66604V5.36048H16.4384C16.4991 5.36048 16.5574 5.38463 16.6004 5.4276C16.6434 5.47058 16.6675 5.52887 16.6675 5.58965V6.74937Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M7.63867 10.2227H17.3609V11.6115H7.63867V10.2227Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M7.63867 13H17.3609V14.3889H7.63867V13Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M7.63867 15.7773H17.3609V17.1662H7.63867V15.7773Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M7.63867 18.5547H17.3609V19.9436H7.63867V18.5547Z"
                  fill="#C9C9C9"
                />
              </svg>
            </samp>
            <input type="text" placeholder="Search.." id="myInput" />
          </div>
        </div>
        <hr />

        <div className="insights-ir-team-topic-main-dev">
          <div className="insights-it-team-qa-menu">
            <div
              style={{ background: "white", position: "sticky", top: "85px" }}
            >
              <p className="insights-it-team-qa-title">Topics</p>
              <div className="insights-it-team-btn-dev">
                {topics.map((topic: string, index: number) => (
                  <button
                    key={index}
                    onClick={() => {
                      scrollToSection(index);
                      setTopicsToogle(!topicsToogle);
                      setTopicText(topic);
                    }}
                    className={`button ${topicText == topic && "active-btn"}`}
                  >
                    <span>{topic}</span>
                  </button>
                ))}
              </div>
            </div>
            <div className="content-dev">
              {htmlString &&
                htmlString.map((elem: string, index: number) => (
                  <InView
                  key={`content-dev-${index}`}
                  
                    threshold={0.2} // Adjust threshold to 0.5 for testing
                    rootMargin="0% 0% -5% 0%" // Adjust rootMargin if needed
                    // delay={500}
                    onChange={(inView) => {
                      if (inView) {
                        setTopicText(topics[index]);
                      }
                    }}
                  >
                    {" "}
                    <div
                      key={index}
                      id={`btn-${index}`}
                      className={`btn-${index}`}
                    >
                      {parser(elem)}
                    </div>
                  </InView>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="insigths-ir-team-main-mobile-dev">
        <div className="insights-it-title-dev">
          <h1>Insights from our IR Team</h1>
          <p>Q&A for Investors</p>
        </div>
        <div className="insigths-ir-team-content-dev">
          <div className="search-dev">
            <samp
              onClick={() => setTopicsToogle(!topicsToogle)}
              className="search-selected-icon"
            >
              {topicsToogle ? (
                <svg
                  width="29"
                  height="30"
                  viewBox="0 0 29 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="29" height="30" rx="1" fill="#E5F0FC" />
                  <path
                    d="M22.0239 6.06707H20.5132V7.4417H21.8326V24.6245H7.31938V7.4417H8.63876V6.06707H7.31938C7.15783 6.04273 6.99315 6.05436 6.83623 6.10119C6.6793 6.14802 6.53369 6.22899 6.40901 6.33876C6.28432 6.44853 6.1834 6.5846 6.1129 6.73799C6.04239 6.89139 6.00392 7.05861 6 7.22863V24.8376C6.00086 24.9911 6.03073 25.1428 6.08789 25.2842C6.14505 25.4256 6.22838 25.554 6.33313 25.6618C6.43788 25.7697 6.562 25.855 6.6984 25.9129C6.83479 25.9708 6.9808 26.0001 7.12807 25.9992H22.0239C22.1712 26.0001 22.3172 25.9708 22.4536 25.9129C22.59 25.855 22.7141 25.7697 22.8188 25.6618C22.9236 25.554 23.0069 25.4256 23.0641 25.2842C23.1212 25.1428 23.1511 24.9911 23.152 24.8376V7.22863C23.1511 7.07519 23.1212 6.92343 23.0641 6.78201C23.0069 6.6406 22.9236 6.5123 22.8188 6.40443C22.7141 6.29657 22.59 6.21126 22.4536 6.15338C22.3172 6.0955 22.1712 6.06617 22.0239 6.06707Z"
                    fill="#888888"
                  />
                  <path
                    d="M19.8539 7.66877C19.8522 7.2446 19.6897 6.83832 19.4018 6.53838C19.1139 6.23844 18.7239 6.06914 18.3168 6.06733H17.1294C16.984 5.47596 16.6539 4.9518 16.1911 4.57748C15.7282 4.20317 15.159 4 14.5731 4C13.9871 4 13.4179 4.20317 12.955 4.57748C12.4922 4.9518 12.1621 5.47596 12.0168 6.06733H10.8359C10.4288 6.06914 10.0388 6.23844 9.75095 6.53838C9.46306 6.83832 9.30056 7.2446 9.29883 7.66877V10.1912H19.8539V7.66877ZM18.5345 8.81659H10.6182V7.66877C10.6182 7.63899 10.6238 7.60949 10.6348 7.58197C10.6457 7.55446 10.6618 7.52945 10.682 7.50839C10.7022 7.48733 10.7262 7.47062 10.7526 7.45922C10.779 7.44783 10.8073 7.44196 10.8359 7.44196H13.257V6.75465C13.257 6.39007 13.396 6.04043 13.6434 5.78264C13.8908 5.52484 14.2264 5.38002 14.5764 5.38002C14.9263 5.38002 15.2619 5.52484 15.5093 5.78264C15.7567 6.04043 15.8957 6.39007 15.8957 6.75465V7.44196H18.3168C18.3745 7.44196 18.4299 7.46586 18.4707 7.50839C18.5116 7.55093 18.5345 7.60862 18.5345 7.66877V8.81659Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.95703 12.2539H19.1927V13.6285H9.95703V12.2539Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.95703 15H19.1927V16.3746H9.95703V15Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.95703 17.75H19.1927V19.1246H9.95703V17.75Z"
                    fill="#888888"
                  />
                  <path
                    d="M9.95703 20.5039H19.1927V21.8785H9.95703V20.5039Z"
                    fill="#888888"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="26"
                  viewBox="0 0 25 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.3407 3.97345H18.7504V5.36234H20.1393V22.7235H4.86155V5.36234H6.25043V3.97345H4.86155C4.69148 3.94886 4.51813 3.9606 4.35294 4.00792C4.18774 4.05524 4.03446 4.13705 3.90321 4.24796C3.77196 4.35887 3.66572 4.49635 3.5915 4.65133C3.51728 4.80632 3.47678 4.97528 3.47266 5.14706V22.9387C3.47357 23.0938 3.505 23.2471 3.56517 23.39C3.62534 23.5329 3.71307 23.6625 3.82334 23.7715C3.93361 23.8805 4.06426 23.9667 4.20785 24.0251C4.35143 24.0836 4.50512 24.1133 4.66016 24.1123H20.3407C20.4957 24.1133 20.6494 24.0836 20.793 24.0251C20.9366 23.9667 21.0673 23.8805 21.1775 23.7715C21.2878 23.6625 21.3755 23.5329 21.4357 23.39C21.4959 23.2471 21.5273 23.0938 21.5282 22.9387V5.14706C21.5273 4.99203 21.4959 4.83869 21.4357 4.69581C21.3755 4.55293 21.2878 4.42329 21.1775 4.31431C21.0673 4.20533 20.9366 4.11914 20.793 4.06065C20.6494 4.00217 20.4957 3.97254 20.3407 3.97345Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M18.0564 5.58965C18.0546 5.16108 17.8835 4.75058 17.5805 4.44753C17.2774 4.14448 16.8669 3.97342 16.4384 3.97159H15.1884C15.0354 3.37408 14.6879 2.84448 14.2007 2.46629C13.7134 2.08809 13.1142 1.88281 12.4974 1.88281C11.8806 1.88281 11.2814 2.08809 10.7941 2.46629C10.3069 2.84448 9.95941 3.37408 9.80642 3.97159H8.56337C8.1348 3.97342 7.7243 4.14448 7.42125 4.44753C7.1182 4.75058 6.94714 5.16108 6.94531 5.58965V8.13826H18.0564V5.58965ZM16.6675 6.74937H8.3342V5.58965C8.3342 5.55955 8.34013 5.52975 8.35165 5.50195C8.36316 5.47415 8.38004 5.44888 8.40132 5.4276C8.4226 5.40632 8.44787 5.38944 8.47567 5.37793C8.50347 5.36641 8.53327 5.36048 8.56337 5.36048H11.112V4.66604C11.112 4.29768 11.2583 3.94441 11.5188 3.68394C11.7792 3.42348 12.1325 3.27715 12.5009 3.27715C12.8692 3.27715 13.2225 3.42348 13.483 3.68394C13.7434 3.94441 13.8898 4.29768 13.8898 4.66604V5.36048H16.4384C16.4991 5.36048 16.5574 5.38463 16.6004 5.4276C16.6434 5.47058 16.6675 5.52887 16.6675 5.58965V6.74937Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M7.63867 10.2227H17.3609V11.6115H7.63867V10.2227Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M7.63867 13H17.3609V14.3889H7.63867V13Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M7.63867 15.7773H17.3609V17.1662H7.63867V15.7773Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M7.63867 18.5547H17.3609V19.9436H7.63867V18.5547Z"
                    fill="#C9C9C9"
                  />
                </svg>
              )}
            </samp>
            <input type="text" placeholder="Search.." id="myInput" />
          </div>
          <hr />
          {topicsToogle && (
            <div className="btn-abou-dev">
              <div className="topics-container">
                <h3>Topics</h3>
                <div className="button-grid">
                  {topics.map((topic: string, index: number) => (
                    <Link
                      offset={-460}
                      to={`btn-${index}`}
                      smooth={true}
                      duration={500}
                    >
                      <button
                        onClick={() => {
                          setTopicsToogle(!topicsToogle);
                          setTopicText(topic);
                        }}
                        className={`button ${
                          topicText == topic && "active-btn"
                        }`}
                        key={index}
                      >
                        <span>{topic}</span>
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="content-dev">
            {htmlString &&
              htmlString.map((elem: string, index: number) => (
                <Element name={`btn-${index}`} className={`btn-${index}`}>
                  {parser(elem)}
                </Element>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Qa;
