import React, { useState, useEffect } from "react";
import "./why.scss";
import "../home/Home.scss";
import "../AboutUs/AboutUs.scss";
import { Button } from "@mui/material";
import { GetPubliccmsContent, fetIpAddress } from "../../utils/Api/api";
import parser from "html-react-parser";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";

const Why: React.FC = () => {
  const loaction = useGeoLocation();
  const [htmlString, setHtmlStr] = useState([]);
  const [remoteWork, setRemoteWork] = useState(3);
  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };
  const cmsContent = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "6801",
      });

      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {
      // toast.error(
      //   `Something went wrong, please try again`,
      //   toastCommonestyle as any
      // );
    }
  };

  useEffect(() => {
    document.title = "TICCA | WHY ChooseUs";
    cmsContent();
    window.scrollTo(0, 0); 
  }, []);

  return (
    <>
      <div className="home-container">
        <div className="home-bg-container why-bg-img">
          <div className="home-bg-container-dev">
            {htmlString[0] && <>{parser(htmlString[0])}</>}
          </div>
        </div>
        <div className="who-global-collective-main-dev">
          {htmlString[1] && <>{parser(htmlString[1])}</>}
          <div className="who-global-card-main-dev">
            <div className="who-global-div-flex">
              {htmlString[2] && <>{parser(htmlString[2])}</>}
            </div>
            <img
              src="
https://public.ticca.com/images/png/WhyTransformingTheFutureOfRemoteWork.png"
              alt="success"
            />
          </div>
        </div>
        <div className="why-remote-work-main-dev">
          <div className="card-value-main-dev">
            <div className="card-value-dev">
              {htmlString.length > 3 && <>{parser(htmlString[remoteWork])}</>}
            </div>
            <div className="card-value-dev-button">
              <Button
                onClick={() => setRemoteWork(21)}
                type="button"
                className="values-button eagerness-button"
              >
                Eagerness
              </Button>
              <Button
                onClick={() => setRemoteWork(13)}
                type="button"
                className="values-button persistence-button"
              >
                Persistence
              </Button>
              <Button
                type="button"
                className="values-button dependability-button"
                onClick={() => setRemoteWork(14)}
              >
                Dependability
              </Button>
              <Button
                onClick={() => setRemoteWork(4)}
                type="button"
                className="values-button loyalty-button"
              >
                Loyalty
              </Button>
              <Button
                onClick={() => setRemoteWork(5)}
                type="button"
                className="values-button integrity-button"
              >
                Integrity
              </Button>
              <Button
                onClick={() => setRemoteWork(22)}
                type="button"
                className="values-button leadership-button"
              >
                LEADERSHIP
              </Button>
              <Button
                type="button"
                className="values-button positiveness1-button"
                onClick={() => setRemoteWork(3)}
              >
                Values
              </Button>
              <Button
                type="button"
                className="values-button pro-activeness-button"
                onClick={() => setRemoteWork(12)}
              >
                Pro-activeness
              </Button>
              <Button
                onClick={() => setRemoteWork(11)}
                type="button"
                className="values-button adaptability-button"
              >
                Adaptability
              </Button>
              <Button
                type="button"
                onClick={() => setRemoteWork(10)}
                className="values-button professionalism-button"
              >
                Professionalism
              </Button>
              <Button
                onClick={() => setRemoteWork(19)}
                type="button"
                className="values-button positiveness-button"
              >
                Positiveness
              </Button>
              <Button
                onClick={() => setRemoteWork(9)}
                type="button"
                className="values-button punctuality-button"
              >
                Punctuality
              </Button>
              <Button
                onClick={() => setRemoteWork(16)}
                type="button"
                className="values-button selflessness-button"
              >
                Selflessness
              </Button>
              <Button
                onClick={() => setRemoteWork(15)}
                type="button"
                className="values-button humbleness-button"
              >
                Humbleness
              </Button>
              <Button
                onClick={() => setRemoteWork(8)}
                type="button"
                className="values-button dedication-button"
              >
                Dedication
              </Button>
              <Button
                type="button"
                className="values-button Self-Confidence-button"
                onClick={() => setRemoteWork(17)}
              >
                Self-Confidence
              </Button>
              <Button
                onClick={() => setRemoteWork(18)}
                type="button"
                className="values-button self-motivation-button"
              >
                Self-Motivation
              </Button>
              <Button
                onClick={() => setRemoteWork(7)}
                type="button"
                className="values-button honestry-button"
              >
                Honesty
              </Button>
              <Button
                onClick={() => setRemoteWork(20)}
                type="button"
                className="values-button optimistic-button"
              >
                Optimistic
              </Button>
              <Button
                onClick={() => setRemoteWork(6)}
                type="button"
                className="values-button ethics-button"
              >
                Ethics
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Why;
