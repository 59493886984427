import React from "react";
import "./footer.scss";
const Footer = () => {
  return (
    <>
      <div className="footer-main-dev">
        <div className="footer-dev-flex">
          <div>
            <p className="footer-list-title">TICCA</p>
            <ul>
              <li>Desktop app</li>
              <li>Mobile app</li>
              <li>Integrations</li>
              <li>Features</li>
              <li>Security</li>
            </ul>
          </div>
          <div>
            <p className="footer-list-title">Products</p>
            <ul>
              <li>Project Management</li>
              <li>Team</li>
              <li>POW</li>
              <li>TimeTrack</li>
              <li>Meeting</li>
              <li>Calendar</li>
              <li>Payroll</li>
              <li>Kanban</li>
              <li>Analytics</li>
              <li>Scheduling</li>
              <li>Map</li>
              <li>GANT</li>
              <li>GPS/Geolocation</li>
            </ul>
          </div>
          <div>
            <p className="footer-list-title">Support</p>
            <ul>
              <li>Contact us</li>
              <li>Privacy & terms</li>
              <li>Cookie policy</li>
              <li>NDA</li>
           
            </ul>
          </div>
          <div>
            <p className="footer-list-title">Company</p>
            <ul>
              <li>What</li>
              <li>Where</li>
              <li>How</li>
              <li>Who</li>
              <li>When</li>
              <li>Why</li>
              <li>Investor</li>
              <li>Contact</li>
            </ul>
          </div>
        </div>
        <div className="footer-social-media-dev">
          <div className="footer-social-icon-dev">
            <svg
              width="22"
              height="18"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.72282 17.7881C14.7735 17.7881 19.1775 11.1121 19.1775 5.33341C19.1775 5.14541 19.1775 4.95741 19.1695 4.76941C20.0269 4.14824 20.7669 3.37935 21.3548 2.49874C20.554 2.85241 19.7052 3.0856 18.8362 3.19074C19.7519 2.64275 20.4375 1.78091 20.7655 0.765408C19.9056 1.27545 18.9643 1.63357 17.9828 1.82408C17.5736 1.38716 17.0791 1.03897 16.5297 0.801125C15.9804 0.563278 15.3881 0.44084 14.7895 0.441408C13.6283 0.442468 12.515 0.904313 11.694 1.72553C10.873 2.54675 10.4115 3.66021 10.4108 4.82141C10.4108 5.16274 10.4535 5.49474 10.5215 5.82008C8.7808 5.73248 7.07798 5.27982 5.52357 4.49148C3.96917 3.70313 2.59791 2.59673 1.49882 1.24408C0.939975 2.20892 0.769271 3.35028 1.02136 4.43641C1.27346 5.52254 1.92945 6.47203 2.85615 7.09207C2.16196 7.06783 1.48322 6.88056 0.874818 6.54541V6.60541C0.875901 7.61571 1.22568 8.59467 1.86507 9.3769C2.50446 10.1591 3.39426 10.6967 4.38415 10.8987C4.00852 11.0023 3.62046 11.0539 3.23082 11.0521C2.95082 11.0521 2.67615 11.0267 2.41215 10.9761C2.69118 11.8462 3.23553 12.6072 3.96889 13.1524C4.70225 13.6976 5.58783 13.9996 6.50148 14.0161C4.95091 15.2349 3.03511 15.8963 1.06282 15.8934C0.714801 15.8949 0.367023 15.8749 0.0214844 15.8334C2.02321 17.1106 4.34836 17.7888 6.72282 17.7881Z"
                fill="#F7F5F2"
              />
            </svg>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.3538 11.1081C21.3538 8.2791 20.2299 5.56599 18.2296 3.5656C16.2292 1.56521 13.5161 0.441406 10.6871 0.441406C8.00356 0.442996 5.4192 1.45589 3.44925 3.27816C1.47931 5.10042 0.268494 7.59818 0.0582128 10.2735C-0.152068 12.9487 0.653631 15.605 2.31468 17.7127C3.97573 19.8203 6.3701 21.2246 9.02042 21.6454V14.1921H6.31242V11.1081H9.02042V8.75874C9.02042 6.08541 10.6124 4.60807 13.0498 4.60807C14.2164 4.60807 15.4364 4.81607 15.4364 4.81607V7.44141H14.0911C12.7658 7.44141 12.3538 8.26407 12.3538 9.10807V11.1081H15.3124L14.8391 14.1921H12.3538V21.6454C14.8628 21.2482 17.1478 19.9687 18.7977 18.037C20.4475 16.1054 21.3539 13.6484 21.3538 11.1081Z"
                fill="#F7F5F2"
              />
            </svg>
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.2188 1.92134C20.5508 2.25201 20.7882 2.66401 20.9108 3.11601C21.3495 4.78001 21.3548 8.25068 21.3548 8.25068C21.3548 8.25068 21.3548 11.7227 20.9108 13.3867C20.7888 13.8385 20.5503 14.2505 20.2193 14.5813C19.8882 14.9121 19.4761 15.1503 19.0242 15.272C17.3615 15.7187 10.6882 15.7187 10.6882 15.7187C10.6882 15.7187 4.01615 15.7187 2.35482 15.272C1.90275 15.1506 1.49053 14.9125 1.15942 14.5816C0.82831 14.2507 0.589931 13.8387 0.468151 13.3867C0.0214844 11.7227 0.0214844 8.25068 0.0214844 8.25068C0.0214844 8.25068 0.0214844 4.78001 0.468151 3.11734C0.588466 2.66399 0.826058 2.25032 1.15702 1.91795C1.48798 1.58559 1.90064 1.34624 2.35348 1.22401C4.01482 0.777344 10.6882 0.777344 10.6882 0.777344C10.6882 0.777344 17.3602 0.777344 19.0242 1.23068C19.4775 1.35201 19.8882 1.59068 20.2188 1.92134ZM14.0988 8.25068L8.55482 5.05068V11.4507L14.0988 8.25068Z"
                fill="#F7F5F2"
              />
            </svg>
          </div>
          <hr />
          <div className="footer-social-icon-dev">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.4375 0.109375C3.2225 0.109375 0.6875 2.64437 0.6875 7.85938C0.6875 13.0744 3.2225 15.6094 8.4375 15.6094C13.6525 15.6094 16.1875 13.0744 16.1875 7.85938C16.1875 2.64437 13.6525 0.109375 8.4375 0.109375ZM14.1515 4.60938H11.5575C11.4879 3.67944 11.2778 2.76541 10.9345 1.89838C11.6524 2.05036 12.3216 2.37737 12.8827 2.85033C13.4437 3.32329 13.8792 3.92754 14.1505 4.60938H14.1515ZM14.6875 7.85938C14.6895 8.44538 14.6455 9.03037 14.5545 9.60938H11.6575C11.6795 9.03037 11.6875 8.44238 11.6875 7.85938C11.6875 7.27637 11.6795 6.68837 11.6575 6.10938H14.5545C14.6445 6.68837 14.6895 7.27338 14.6875 7.85938ZM8.4375 14.1094C7.8215 14.1094 7.1375 13.6994 6.8395 11.1094H10.0355C9.7375 13.6994 9.0535 14.1094 8.4375 14.1094ZM6.7255 9.60938C6.7015 9.08537 6.6875 8.50937 6.6875 7.85938C6.6875 7.20937 6.7015 6.63338 6.7255 6.10938H10.1495C10.1735 6.63338 10.1875 7.20937 10.1875 7.85938C10.1875 8.50937 10.1735 9.08537 10.1495 9.60938H6.7255ZM2.1875 7.85938C2.1855 7.27338 2.2295 6.68837 2.3205 6.10938H5.2175C5.1955 6.68837 5.1875 7.27637 5.1875 7.85938C5.1875 8.44238 5.1955 9.03037 5.2175 9.60938H2.3205C2.22994 9.03048 2.18547 8.44531 2.1875 7.85938ZM8.4375 1.60938C9.0535 1.60938 9.7375 2.01938 10.0355 4.60938H6.8395C7.1375 2.01938 7.8215 1.60938 8.4375 1.60938ZM5.9405 1.89838C5.59716 2.76541 5.38711 3.67944 5.3175 4.60938H2.7235C2.99484 3.92742 3.43051 3.32309 3.99175 2.85012C4.553 2.37716 5.22242 2.05022 5.9405 1.89838ZM2.7235 11.1094H5.3175C5.3875 12.0394 5.5975 12.9534 5.9405 13.8204C5.22257 13.6685 4.55331 13.3415 3.99224 12.8685C3.43116 12.3956 2.99568 11.7913 2.7245 11.1094H2.7235ZM10.9335 13.8204C11.2772 12.9534 11.4876 12.0394 11.5575 11.1094H14.1515C13.8802 11.7913 13.4445 12.3957 12.8832 12.8686C12.322 13.3416 11.6516 13.6685 10.9335 13.8204Z"
                fill="#F7F5F2"
              />
            </svg>
            <p>English </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
