import { Button, CircularProgress } from "@mui/material";
import "./question.scss";
import {
  GetPubliccmsContent,
  GetTalkToOurFounder,
  PostTalkToOurFounder,
  fetIpAddress,
} from "../../utils/Api/api";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import parser from "html-react-parser";
import { ChangeEvent, useEffect, useState } from "react";
import Pie from "../../components/Pie/pie";
import { toast } from "react-toastify";
import { toastCommonestyle } from "../../utils/Toast";
import { jsonToFrom } from "../../utils/JsonToForm";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../utils/pageRoutes";

interface CheckboxState {
  stage: string;
}

const Question = () => {
  const loaction = useGeoLocation();
  const navigator = useNavigate();
  const [htmlString, setHtmlStr] = useState<string[]>([]);
  const [removeHtml, setHtmlRemove] = useState<string[]>([]);
  const [value, setValue] = useState(0);
  const [questionObj, setQuestionObj] = useState<{ [key: string]: string }>({});
  const [isFormComplete, setIsFormComplete] = useState(false); // Track if form is complete
  const [selectedStage, setSelectedStage] = useState<CheckboxState>({
    stage: "",
  });
  const [selectedStageLike, setSelectedStageLike] = useState<CheckboxState>({
    stage: "",
  });
  const [selectedStageLikeIndustry, setSelectedStageLikeIndustry] =
    useState<CheckboxState>({
      stage: "",
    });

    useEffect(() => {
      const nonEmptyFields = countNonEmptyValues(questionObj);
      setIsFormComplete(nonEmptyFields === removeHtml.length); 
      let sum = countNonEmptyValues(questionObj);
      setValue((sum / removeHtml.length) * 100);
    }, [questionObj, removeHtml]);
  
    const countNonEmptyValues = (surveyData: { [key: string]: string }): number => {
      return Object.keys(surveyData).reduce((count, key) => {
        const value = surveyData[key]?.trim(); 
        return count + (value ? 1 : 0); 
      }, 0);
    };
  
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>, no: number) => {
      const updatedQuestionObj = {
        ...questionObj,
        [removeHtml[no]]: event.target.value.toString()
      };
      setQuestionObj(updatedQuestionObj);
    };
  
    const handleCheckboxChange = (stage: string, no: number) => {
      const updatedStage = selectedStage.stage === stage ? "" : stage;
      setSelectedStage({ stage: updatedStage });
      setQuestionObj({ ...questionObj, [removeHtml[no]]: updatedStage });
    };
  
    const handleCheckboxChangeLike = (stage: string, no: number) => {
      const updatedStage = selectedStageLike.stage === stage ? "" : stage;
      setSelectedStageLike({ stage: updatedStage });
      setQuestionObj({ ...questionObj, [removeHtml[no]]: updatedStage });
    };
  
    const handleCheckboxChangeLikeIndustry = (stage: string, no: number) => {
      const updatedStage = selectedStageLikeIndustry.stage === stage ? "" : stage;
      setSelectedStageLikeIndustry({ stage: updatedStage });
      setQuestionObj({ ...questionObj, [removeHtml[no]]: updatedStage });
    };

  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };

  const getTalk = async () => {
    try {
      const response = await GetTalkToOurFounder({
        ip_address:  await fetchIpAddress(),
        latitude: await loaction.coordinates.lat,
        longitude: await loaction.coordinates.lng,
      });
      if (response.data.status == 200) {
        if (response?.data?.status_code == 2) {
          navigator("/")
        } 
      }
     
    } catch (error) {}
  };
  const handleSubmit = async () => {
    const formData = jsonToFrom({
      ip_address: await fetchIpAddress(),
      latitude: await loaction.coordinates.lat,
      longitude: await loaction.coordinates.lng,
    });
    formData.append("survey", JSON.stringify(questionObj));

    try {
      const response = await PostTalkToOurFounder(formData);
      toast.success(response.data.message, toastCommonestyle as any);
    } catch (error) {}
  };

  const cmsContent = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "7033",
      });
      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {}
  };

  function removeHTMLTags(str: string): string {
    const doc = new DOMParser().parseFromString(str, "text/html");
    let cleanedStr = doc.body.textContent || "";
    cleanedStr = cleanedStr.replace(/\n/g, "").replace(/\s+/g, " ").trim();
    return cleanedStr;
  }

  useEffect(() => {
    cmsContent();
    getTalk()
  }, []);

  useEffect(() => {
    if (htmlString.length > 0) {
      let cleanData = htmlString.map((item) => removeHTMLTags(item));
      setHtmlRemove(cleanData);
    }
  }, [htmlString]);
  return (
    <>
      <div className="valuable-question-main-dev">
        <div className="valuable-question-dev">
          <p className="valuable-question-title">
            Thank you for your valuable time and insights!
          </p>
          <p>
            At TICCA, we’re fuelled by passion and purpose, and we strive for
            success. Your feedback is essential in helping us refine our path.
            Your thoughts will help shape what’s next!
          </p>
        </div>
        {htmlString[0] && (
          <>
            {parser(htmlString[0])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 0)}
            ></textarea>
          </>
        )}
        {htmlString[1] && (
          <>
            {" "}
            {parser(htmlString[1])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 1)}
            ></textarea>
          </>
        )}
        {htmlString[2] && (
          <>
            {parser(htmlString[2])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 2)}
            ></textarea>
          </>
        )}
        {htmlString[3] && (
          <>
            {parser(htmlString[3])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 3)}
            ></textarea>
          </>
        )}
        {htmlString[4] && (
          <>
            {parser(htmlString[4])}
            <label>
              <input
                checked={selectedStage.stage === "Seed Stage"}
                onChange={() => handleCheckboxChange("Seed Stage", 4)}
                type="checkbox"
              />
              Seed Stage
            </label>
            <label>
              <input
                checked={selectedStage.stage === "Early Stage"}
                onChange={() => handleCheckboxChange("Early Stage", 4)}
                type="checkbox"
              />
              Early Stage
            </label>
            <label>
              <input
                checked={selectedStage.stage === "Growth Stage"}
                onChange={() => handleCheckboxChange("Growth Stage", 4)}
                type="checkbox"
              />
              Growth Stage
            </label>
            <label>
              <input
                checked={selectedStage.stage === "Late Stage"}
                onChange={() => handleCheckboxChange("Late Stage", 4)}
                type="checkbox"
              />
              Late Stage
            </label>
            <label>
              <input
                checked={selectedStage.stage === "All Stages"}
                onChange={() => handleCheckboxChange("All Stages", 4)}
                type="checkbox"
              />
              All stages{" "}
            </label>
          </>
        )}
        {htmlString[5] && (
          <>
            {parser(htmlString[5])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 5)}
            ></textarea>
          </>
        )}
        {htmlString[6] && (
          <>
            {parser(htmlString[6])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 6)}
            ></textarea>
          </>
        )}
        {htmlString[7] && (
          <>
            {parser(htmlString[7])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 7)}
            ></textarea>
          </>
        )}
        {htmlString[8] && (
          <>
            {parser(htmlString[8])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 8)}
            ></textarea>
          </>
        )}
        {htmlString[9] && (
          <>
            {parser(htmlString[9])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 9)}
            ></textarea>
          </>
        )}
        {htmlString[10] && (
          <>
            {parser(htmlString[10])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 10)}
            ></textarea>
          </>
        )}
        {htmlString[11] && (
          <>
            {parser(htmlString[11])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 11)}
            ></textarea>
          </>
        )}
        {htmlString[12] && (
          <>
            {parser(htmlString[12])}
            <label>
              <input
                checked={selectedStageLike.stage === "Extremely likely"}
                onChange={() =>
                  handleCheckboxChangeLike("Extremely likely", 12)
                }
                type="checkbox"
              />
              Extremely likely
            </label>
            <label>
              <input
                checked={selectedStageLike.stage === "Very likely"}
                onChange={() => handleCheckboxChangeLike("Very likely", 12)}
                type="checkbox"
              />
              Very likely
            </label>
            <label>
              <input
                checked={selectedStageLike.stage === "Moderately likely"}
                onChange={() =>
                  handleCheckboxChangeLike("Moderately likely", 12)
                }
                type="checkbox"
              />
              Moderately likely
            </label>
            <label>
              <input
                checked={selectedStageLike.stage === "Slightly likely"}
                onChange={() => handleCheckboxChangeLike("Slightly likely", 12)}
                type="checkbox"
              />
              Slightly likely
            </label>
            <label>
              <input
                checked={selectedStageLike.stage === "Not likely at all"}
                onChange={() =>
                  handleCheckboxChangeLike("Not likely at all", 12)
                }
                type="checkbox"
              />
              Not likely at all
            </label>
          </>
        )}
        {htmlString[13] && (
          <>
            {parser(htmlString[13])}
            <label>
              <input
                checked={selectedStageLikeIndustry.stage === "Extremely likely"}
                onChange={() =>
                  handleCheckboxChangeLikeIndustry("Extremely likely", 13)
                }
                type="checkbox"
              />
              Extremely likely
            </label>
            <label>
              <input
                checked={selectedStageLikeIndustry.stage === "Very likely"}
                onChange={() =>
                  handleCheckboxChangeLikeIndustry("Very likely", 13)
                }
                type="checkbox"
              />
              Very likely
            </label>
            <label>
              <input
                checked={
                  selectedStageLikeIndustry.stage === "Moderately likely"
                }
                onChange={() =>
                  handleCheckboxChangeLikeIndustry("Moderately likely", 13)
                }
                type="checkbox"
              />
              Moderately likely
            </label>
            <label>
              <input
                checked={selectedStageLikeIndustry.stage === "Slightly likely"}
                onChange={() =>
                  handleCheckboxChangeLikeIndustry("Slightly likely", 13)
                }
                type="checkbox"
              />
              Slightly likely
            </label>
            <label>
              <input
                checked={
                  selectedStageLikeIndustry.stage === "Not likely at all"
                }
                onChange={() =>
                  handleCheckboxChangeLikeIndustry("Not likely at all", 13)
                }
                type="checkbox"
              />
              Not likely at all
            </label>
          </>
        )}
        {htmlString[14] && (
          <>
            {parser(htmlString[14])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 14)}
            ></textarea>
          </>
        )}
        {htmlString[15] && (
          <>
            {parser(htmlString[15])}
            <textarea
              rows={4}
              placeholder="Write your answer here "
              id="story"
              name="story"
              onChange={(e) => handleChange(e, 15)}
            ></textarea>
          </>
        )}
        <Button
          onClick={() => {
            handleSubmit();
          }}
          style={{
            margin: "auto",
            width: "100%",
            fontFamily: "var(--Roboto-Regular)",
          }}
          variant="contained"
          color="primary"
          type="button"
          disabled={!isFormComplete}
        >
          SUBMIT
        </Button>
      </div>
      <div className="cricle-pro-dev">
        <Pie percentage={value} colour="rgba(74, 172, 0, 1)" />
      </div>
    </>
  );
};
export default Question;
