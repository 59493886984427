import React, { useContext, useEffect, useState } from "react";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import { PageRoutes } from "../../utils/pageRoutes";
import {
  GetLogout,
  LoginTokenVerifty,
  fetIpAddress,
  getUserProfile,
  requestPPM,
  AccessBusinessSummary,
  BusinessSummaryAccessRequest,
  PostTalkToOurFounder,
} from "../../utils/Api/api";
import useGeoLocation from "../geoLocation/GeoLoaction";
import { Button } from "@mui/material";
import "../../pages/InvestorCentrer/investorCenter.scss";
import { Context } from "../../contextApi/contextProvider";
import PpmPop from "../../utils/popMenu/PpmPop";
import Pending from "../../utils/popMenu/pendingPop";

export interface loginToken {
  access_token: string | null;
}

function NavBar() {
  const {
    userLogin,
    setUserLogin,
    userShowPPM,
    setUserShowPPM,
    busAccessStatus,
    setBusAccessStatus,
  } = useContext(Context);
  const [menuTogle, setmenuTogle] = useState(false);
  const navigation = useNavigate();
  const loaction = useGeoLocation();
  const [userName, setUserName] = useState("");
  const [colorChange, setColorchange] = useState(true);
  const [investorMenu, setInvestoeMenu] = useState(false);
  const [ppm, setPpm] = useState(false);
  const [ppmPop, setppmPop] = useState(false);
  const [ppmLock, setPpmLock] = useState(false);
  const [pendingPop, setpendingPop] = useState(false);
  const location = useGeoLocation();

  const fetchData = async (data: requestPPM) => {
    try {
      const response1 = await AccessBusinessSummary(data);
      if (response1.data?.status == 1) {
        setUserShowPPM(true);
        setBusAccessStatus("accepted");
      } else if (response1.data?.status == 3) {
        setUserShowPPM(false);
        setBusAccessStatus("pending");
      } else {
        setUserShowPPM(false);
        setBusAccessStatus("key");
      }
    } catch (error) {
      setUserShowPPM(false);
    }
  };
  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };

  const verftyLgoinUser = async () => {
    const token = JSON.parse(localStorage.getItem("access_token") as any);
    if (token) {
      try {
        const { data } = await LoginTokenVerifty({ access_token: token });
        if (data?.status == 200) {
          setUserLogin(true);
          fetchData({
            ip_address: await fetchIpAddress(),
            latitude: await location?.coordinates.lat,
            longitude: await location?.coordinates.lng,
          });
        }
        if (data?.status !== 200) {
          setUserLogin(false);
        }
      } catch (error) {
        // Notfy(error as string);
        setUserLogin(false);
      }
    }
  };

  useEffect(() => {
    verftyLgoinUser();
  }, []);

  const requestPPM = async () => {
    try {
      const { data } = await BusinessSummaryAccessRequest({
        ip_address: await fetchIpAddress(),
        latitude: await loaction.coordinates.lat,
        longitude: await loaction.coordinates.lng,
      });
      if (data.status == 3) {
        setUserShowPPM(false);
        setBusAccessStatus("pending");
        setpendingPop(true);
      } else if (data.status == 1) {
        if (data.error_code) {
          // toast.info(data.message, toastCommonestyle as any);
          setUserShowPPM(false);
          setBusAccessStatus("key");
          setppmPop(false);
        } else {
          setppmPop(true);
          setUserShowPPM(false);
          setBusAccessStatus("pending");
        }
      } else if (data.status == 4) {
        setBusAccessStatus("accepted");
        navigation(PageRoutes.ExecutiveSummary);
        // toast.success(data.message, toastCommonestyle as any);
      } else {
        // toast.warn(data.message, toastCommonestyle as any);
        setBusAccessStatus("key");
      }
    } catch (error) {}
  };

  const changeNavbarColor = () => {
    if (
      window.location.pathname != PageRoutes.ExecutiveSummary &&
      window.location.pathname != PageRoutes.Intro && window.location.pathname != PageRoutes.qa
    ) {
      if (window.scrollY >= 40) {
        setColorchange(false);
      } else {
        setColorchange(true);
      }
    }
  };
  const UserLogOut = async () => {
    setUserLogin(false);
    localStorage.removeItem("access_token");
    const loginToLtd = { logout: "ltd" };
    const encodedData = encodeURIComponent(JSON.stringify(loginToLtd));
    let Api_URL: any = "";
    const env = process.env.REACT_APP_STAGE_ENV;
    switch (env) {
      case "dev":
        Api_URL = "https://dev.login.ticca.com/logout";
        break;
      case "stage":
        Api_URL = "https://stage.login.ticca.com/logout";
        break;
      case "prod":
        Api_URL = "https://login.ticca.com/logout";
        break;
      default:
        Api_URL = "https://dev.login.ticca.com/logout";
        break;
    }
    window.location.href = `${Api_URL}?data=${encodedData}`;
    try {
      const { data } = await GetLogout({
        ip_address: await fetchIpAddress(),
        latitude: loaction.coordinates?.lat ?? "0.0",
        longitude: loaction.coordinates?.lng ?? "0.0",
      });
      if (data.status == 1) {
        setUserLogin(false);
        localStorage.removeItem("access_token");
      }
    } catch (error) {}
  };
  const UserProfileinfo = async () => {
    try {
      const { data } = await getUserProfile({
        ip_address: await fetchIpAddress(),
        latitude: loaction.coordinates?.lat ?? "0.0",
        longitude: loaction.coordinates?.lng ?? "0.0",
      });

      setUserName(data?.profile?.fname);
      setUserLogin(true);
    } catch (error) {}
  };

  useEffect(() => {
    UserProfileinfo();
  }, []);

  useEffect(() => {
    setPpm(
      window.location.pathname == PageRoutes.ExecutiveSummary ||
        window.location.pathname == PageRoutes.Intro ||
        window.location.pathname == PageRoutes.question ||  window.location.pathname == PageRoutes.qa
    );
    setInvestoeMenu(false);
  }, [window.location.pathname]);
  window.addEventListener("scroll", changeNavbarColor);
  const NavBarComm = () => {
    return (
      <>
        <div className="">
          {ppmPop && <PpmPop setppmPop={setppmPop} />}
          {/* {investorPop && <InvestorPop setInvestorPop={setInvestorPop} />} */}
          {pendingPop && <Pending setpendingPop={setpendingPop} />}
          {ppmLock && (
            <div className="business-summary-popUp">
              <div className="business-summary-main-dev ppm-dev">
                <samp>
                  <svg
                    width="111"
                    height="111"
                    viewBox="0 0 111 111"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M105.104 108.778H5.89691C3.8675 108.778 2.22266 107.133 2.22266 105.104V5.89691C2.22266 3.8675 3.8675 2.22266 5.89691 2.22266H105.104C107.133 2.22266 108.778 3.8675 108.778 5.89691V105.104C108.778 107.133 107.133 108.778 105.104 108.778Z"
                      fill="#D9D9D9"
                    />
                    <path
                      d="M108.778 5.89691C108.778 3.86771 107.133 2.22266 105.104 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V20.5942H108.778L108.778 5.89691Z"
                      fill="black"
                    />
                    <path
                      d="M7.73404 105.104V5.89691C7.73404 3.86771 9.37889 2.22266 11.4083 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V105.104C2.22266 107.133 3.8675 108.778 5.89691 108.778H11.4083C9.3791 108.778 7.73404 107.133 7.73404 105.104Z"
                      fill="#C4C3C1"
                    />
                    <path
                      d="M11.4083 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V20.5942H7.73404V5.89691C7.73404 3.86771 9.3791 2.22266 11.4083 2.22266Z"
                      fill="#55606E"
                    />
                    <path
                      d="M83.0569 77.5469H29.7793V84.8956H83.0569V77.5469Z"
                      fill="white"
                    />
                    <path
                      d="M83.0569 92.2441H29.7793V99.5929H83.0569V92.2441Z"
                      fill="white"
                    />
                    <path
                      d="M70.1972 70.1968H40.8027V48.1508C40.8027 46.1214 42.4476 44.4766 44.477 44.4766H66.523C68.5524 44.4766 70.1972 46.1214 70.1972 48.1508V70.1968Z"
                      fill="#509FE8"
                    />
                    <path
                      d="M49.9884 44.4766H44.477C42.4476 44.4766 40.8027 46.1218 40.8027 48.1508V70.1968H46.3141V48.1508C46.3139 46.1218 47.959 44.4766 49.9884 44.4766Z"
                      fill="#9FCDFF"
                    />
                    <path
                      d="M55.5004 59.1747C57.5297 59.1747 59.1747 57.5297 59.1747 55.5004C59.1747 53.4712 57.5297 51.8262 55.5004 51.8262C53.4712 51.8262 51.8262 53.4712 51.8262 55.5004C51.8262 57.5297 53.4712 59.1747 55.5004 59.1747Z"
                      fill="white"
                    />
                    <path
                      d="M83.0569 81.2207H29.7793V84.895H83.0569V81.2207Z"
                      fill="white"
                    />
                    <path
                      d="M83.0569 95.918H29.7793V99.5922H83.0569V95.918Z"
                      fill="white"
                    />
                    <path
                      d="M16.8016 13.8903C18.2313 13.8903 19.3903 12.7313 19.3903 11.3016C19.3903 9.87189 18.2313 8.71289 16.8016 8.71289C15.3719 8.71289 14.2129 9.87189 14.2129 11.3016C14.2129 12.7313 15.3719 13.8903 16.8016 13.8903Z"
                      fill="#FFC44F"
                    />
                    <path
                      d="M27.1571 13.8903C28.5868 13.8903 29.7458 12.7313 29.7458 11.3016C29.7458 9.87189 28.5868 8.71289 27.1571 8.71289C25.7274 8.71289 24.5684 9.87189 24.5684 11.3016C24.5684 12.7313 25.7274 13.8903 27.1571 13.8903Z"
                      fill="#C4DF64"
                    />
                    <path
                      d="M37.5125 13.8903C38.9422 13.8903 40.1012 12.7313 40.1012 11.3016C40.1012 9.87189 38.9422 8.71289 37.5125 8.71289C36.0828 8.71289 34.9238 9.87189 34.9238 11.3016C34.9238 12.7313 36.0828 13.8903 37.5125 13.8903Z"
                      fill="#FF8C78"
                    />
                    <path
                      d="M13 11.3022C13 13.3988 14.7056 15.1045 16.8022 15.1045C18.8988 15.1045 20.6045 13.3988 20.6045 11.3022C20.6045 9.20562 18.8987 7.5 16.8022 7.5C14.7058 7.5 13 9.20577 13 11.3022ZM18.1774 11.3022C18.1774 12.0606 17.5604 12.6776 16.8021 12.6776C16.0437 12.6776 15.4267 12.0606 15.4267 11.3022C15.4267 10.5439 16.0437 9.92689 16.8021 9.92689C17.5604 9.92689 18.1774 10.544 18.1774 11.3022Z"
                      fill="#FFC44F"
                    />
                    <path
                      d="M23.3555 11.3022C23.3555 13.3988 25.0611 15.1045 27.1577 15.1045C29.2543 15.1045 30.9599 13.3988 30.9599 11.3022C30.9599 9.20562 29.2543 7.5 27.1577 7.5C25.0611 7.5 23.3555 9.20577 23.3555 11.3022ZM28.533 11.3022C28.533 12.0606 27.9161 12.6776 27.1577 12.6776C26.3993 12.6776 25.7824 12.0606 25.7824 11.3022C25.7824 10.5439 26.3993 9.92689 27.1577 9.92689C27.9161 9.92705 28.533 10.544 28.533 11.3022Z"
                      fill="#C4DF64"
                    />
                    <path
                      d="M33.7109 11.3022C33.7109 13.3988 35.4166 15.1045 37.5132 15.1045C39.6098 15.1045 41.3154 13.3988 41.3154 11.3022C41.3154 9.20562 39.6098 7.5 37.5132 7.5C35.4166 7.5 33.7109 9.20577 33.7109 11.3022ZM38.8885 11.3022C38.8885 12.0606 38.2715 12.6776 37.5132 12.6776C36.7548 12.6776 36.1378 12.0606 36.1378 11.3022C36.1378 10.5439 36.7548 9.92689 37.5132 9.92689C38.2715 9.92689 38.8885 10.544 38.8885 11.3022Z"
                      fill="#FF8C78"
                    />
                    <path
                      d="M28.0566 77.5466V84.8954C28.0566 85.8467 28.8277 86.6178 29.779 86.6178H83.0566C84.008 86.6178 84.779 85.8469 84.779 84.8954V77.5466C84.779 76.5953 84.0082 75.8242 83.0566 75.8242H29.779C28.8279 75.8242 28.0566 76.5951 28.0566 77.5466ZM31.5014 79.2688H81.3345V83.1727H31.5014V79.2688Z"
                      fill="#272420"
                    />
                    <path
                      d="M28.0566 99.5926C28.0566 100.544 28.8277 101.315 29.779 101.315H83.0566C84.008 101.315 84.779 100.544 84.779 99.5926V92.2439C84.779 91.2926 84.0082 90.5215 83.0566 90.5215H29.779C28.8279 90.5215 28.0566 91.2923 28.0566 92.2439V99.5926ZM31.5014 93.9663H81.3345V97.8702H31.5014V93.9663Z"
                      fill="#272420"
                    />
                    <path
                      d="M99.592 13.1304C100.543 13.1304 101.314 12.3595 101.314 11.4079C101.314 10.4564 100.544 9.68555 99.592 9.68555H95.9177C94.9664 9.68555 94.1953 10.4564 94.1953 11.4079C94.1953 12.3595 94.9662 13.1304 95.9177 13.1304H99.592Z"
                      fill="black"
                    />
                    <path
                      d="M61.127 11.4079C61.127 12.3593 61.8978 13.1304 62.8494 13.1304H90.4067C91.358 13.1304 92.1291 12.3595 92.1291 11.4079C92.1291 10.4564 91.3583 9.68555 90.4067 9.68555H62.8494C61.8978 9.68576 61.127 10.4566 61.127 11.4079Z"
                      fill="#444242"
                    />
                    <path
                      d="M44.4767 42.7539C41.5009 42.7539 39.0801 45.1747 39.0801 48.1505V70.1965C39.0801 71.1479 39.8512 71.9189 40.8025 71.9189H70.197C71.1483 71.9189 71.9194 71.1481 71.9194 70.1965V48.1505C71.9194 45.1747 69.4985 42.7539 66.5227 42.7539H66.408V37.1276C66.408 32.1258 62.3386 28.0566 57.3371 28.0566H53.6628C48.661 28.0566 44.5919 32.126 44.5919 37.1276V38.9647C44.5919 39.916 45.363 40.6871 46.3143 40.6871C47.2656 40.6871 48.0367 39.9162 48.0367 38.9647V37.1276C48.0367 34.025 50.5607 31.5012 53.663 31.5012H57.3373C60.4398 31.5012 62.9636 34.025 62.9636 37.1276V42.7539H44.4767ZM68.4746 48.1505V68.4741H42.5249V48.1505C42.5249 47.0742 43.4006 46.1985 44.477 46.1985H66.5229C67.5991 46.1987 68.4746 47.0744 68.4746 48.1505Z"
                      fill="#509FE8"
                    />
                    <path
                      d="M55.5002 50.1035C52.5244 50.1035 50.1035 52.5244 50.1035 55.5002C50.1035 57.8738 51.6444 59.8929 53.7778 60.6135V64.686C53.7778 65.6374 54.5488 66.4084 55.5002 66.4084C56.4515 66.4084 57.2226 65.6376 57.2226 64.686V60.6135C59.356 59.8929 60.8968 57.8738 60.8968 55.5002C60.8968 52.5244 58.476 50.1035 55.5002 50.1035ZM55.5002 57.4522C54.4238 57.4522 53.5481 56.5765 53.5481 55.5002C53.5481 54.4238 54.4238 53.5481 55.5002 53.5481C56.5765 53.5481 57.4522 54.4238 57.4522 55.5002C57.4522 56.5765 56.5765 57.4522 55.5002 57.4522Z"
                      fill="#272420"
                    />
                  </svg>
                </samp>

                <p>
                  Register to read our{" "}
                  <samp>Private Placement Memorandum 2024</samp>
                </p>
                <Button
                  onClick={() => {
                    let Api_URL: any = "";
                    const env = process.env.REACT_APP_STAGE_ENV;
                    switch (env) {
                      case "dev":
                        Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                        break;
                      case "stage":
                        Api_URL = process.env.REACT_APP_TICCA_STAGE_SINUP;
                        break;
                      case "prod":
                        Api_URL = process.env.REACT_APP_TICCA_PRO_SINUP;
                        break;
                      default:
                        Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                        break;
                    }

                    window.location.href = Api_URL;
                  }}
                  className="btn-ticca"
                  type="submit"
                >
                  Register
                </Button>
                <samp
                  onClick={() => {
                    setPpmLock(false);
                  }}
                  className="investor-close"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="rgba(241, 246, 255, 1)"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 23L9 9M23 9L9 23"
                      stroke="rgba(241, 246, 255, 1)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </samp>
              </div>
            </div>
          )}
        </div>
        <div className="navbar-dev">
          <div
            onClick={() => {
              setmenuTogle(true);
            }}
            className="mobile-navBar"
          >
            <img
              src={`https://public.ticca.com/images/svg/MenuIconBlack.svg`}
              alt="menuIcon"
            />
          </div>
          <div className="navbar-left-menu">
            <img
              onClick={() => {
                navigation(PageRoutes.home);
              }}
              className="logo-img"
              src={`${"https://public.ticca.com/images/svg/TiccaLogo_Null_EmailVerification_WhiteRedOrangeGreen_150X33.svg"} `}
              alt="logo"
            />
            <div className="navbar-menu-list">
              <ul>
                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.ticcaProduct);
                    }}
                    className={`menuList-title  ${
                      PageRoutes.ticcaProduct === window.location.pathname &&
                      "active"
                    }  `}
                  >
                    What
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.WorkFromAnywhere);
                    }}
                    className={`menuList-title   ${
                      PageRoutes.WorkFromAnywhere ===
                        window.location.pathname && "active"
                    }  `}
                  >
                    Where
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.innovation);
                    }}
                    className={`menuList-title  ${
                      PageRoutes.innovation === window.location.pathname &&
                      "active"
                    } `}
                  >
                    How
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.aboutUs);
                    }}
                    className={`menuList-title  ${
                      PageRoutes.aboutUs === window.location.pathname &&
                      "active"
                    }  `}
                  >
                    Who
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.WhyChooseUs);
                    }}
                    className={`menuList-title  ${
                      PageRoutes.WhyChooseUs === window.location.pathname &&
                      "active"
                    } `}
                  >
                    Why
                  </div>
                </li>

                <li>
                  <div
                    onClick={() => {
                      navigation(PageRoutes.When);
                    }}
                    className={`menuList-title  ${
                      PageRoutes.When === window.location.pathname && "active"
                    } `}
                  >
                    When
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-menu-list">
            <ul>
              <li>
                <div
                  onClick={() => {
                    navigation(PageRoutes.InvestorCenter);
                  }}
                  className={`menuList-title  ${
                    PageRoutes.InvestorCenter === window.location.pathname &&
                    "active"
                  } `}
                  onMouseEnter={() => {
                    setInvestoeMenu(true);
                  }}
                >
                  Investor
                  {userLogin && (
                    <samp>
                      <img
                        className="solution-downArrow"
                        style={{
                          transform: `${
                            investorMenu ? "rotate(0deg)" : "rotate(180deg)"
                          }`,
                        }}
                        src={
                          "https://public.ticca.com/images/svg/ArrowDown.svg"
                        }
                      />
                    </samp>
                  )}
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    navigation(PageRoutes.ContactUs);
                  }}
                  className={`menuList-title  ${
                    PageRoutes.ContactUs === window.location.pathname &&
                    "active"
                  } `}
                >
                  Contact
                </div>
              </li>
              {userLogin ? (
                <li className="contact-img">
                  <div>
                    <img
                      src={
                        "https://public.ticca.com/images/svg/NavProfileIcon.svg"
                      }
                      alt="ContactIcon"
                    />
                  </div>
                  <div className="contact-ab">
                    <div>
                      <p style={{ fontFamily: "var(--OpenSans-Bold)" }}>
                        {userName}
                      </p>
                    </div>
                    <hr />
                    <div
                      onClick={() => {
                        UserLogOut();
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.75 14.75H2.75C2.35218 14.75 1.97064 14.592 1.68934 14.3107C1.40804 14.0294 1.25 13.6478 1.25 13.25V2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H5.75M11 11.75L14.75 8M14.75 8L11 4.25M14.75 8H5.75"
                          stroke="#5C5C5C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <p>Log-out</p>
                    </div>
                    <hr />
                    <div style={{ borderRadius: "0px 0px 8px 8px" }}>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.25 4.5H15.75M14.25 4.5V15C14.25 15.75 13.5 16.5 12.75 16.5H5.25C4.5 16.5 3.75 15.75 3.75 15V4.5M6 4.5V3C6 2.25 6.75 1.5 7.5 1.5H10.5C11.25 1.5 12 2.25 12 3V4.5M7.5 8.25V12.75M10.5 8.25V12.75"
                          stroke="#5C5C5C"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <p>Remove</p>
                    </div>
                  </div>
                </li>
              ) : (
                <li className="contact-img">
                  <Button
                    onClick={() => {
                      let Api_URL: any = "";
                      const env = process.env.REACT_APP_STAGE_ENV;
                      switch (env) {
                        case "dev":
                          Api_URL =
                            "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
                          break;
                        case "stage":
                          Api_URL =
                            "https://stage.login.ticca.com/?domain=stage.www.ticca.ltd";
                          break;
                        case "prod":
                          Api_URL =
                            "https://login.ticca.com/?domain=www.ticca.ltd";
                          break;
                        default:
                          Api_URL =
                            "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
                          break;
                      }
                      window.location.href = Api_URL;
                    }}
                    className="btn-ticca-nav"
                    type="submit"
                  >
                    Log in
                  </Button>
                </li>
              )}
            </ul>
          </div>
          {userLogin ? (
            <li className="contact-img mobile-contact">
              <div>
                <img
                  src={"https://public.ticca.com/images/svg/NavProfileIcon.svg"}
                  alt="ContactIcon"
                />
              </div>
              <div className="contact-ab">
                <div>
                  <p style={{ fontFamily: "var(--OpenSans-Bold)" }}>
                    {userName}
                  </p>
                </div>
                <hr />
                <div
                  onClick={() => {
                    UserLogOut();
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75 14.75H2.75C2.35218 14.75 1.97064 14.592 1.68934 14.3107C1.40804 14.0294 1.25 13.6478 1.25 13.25V2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H5.75M11 11.75L14.75 8M14.75 8L11 4.25M14.75 8H5.75"
                      stroke="#5C5C5C"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <p>Log-out</p>
                </div>
                <hr />
                <div style={{ borderRadius: "0px 0px 8px 8px" }}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.25 4.5H15.75M14.25 4.5V15C14.25 15.75 13.5 16.5 12.75 16.5H5.25C4.5 16.5 3.75 15.75 3.75 15V4.5M6 4.5V3C6 2.25 6.75 1.5 7.5 1.5H10.5C11.25 1.5 12 2.25 12 3V4.5M7.5 8.25V12.75M10.5 8.25V12.75"
                      stroke="#5C5C5C"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p>Remove</p>
                </div>
              </div>
            </li>
          ) : (
            <li className="contact-img  mobile-contact">
              <Button
                onClick={() => {
                  let Api_URL: any = "";
                  const env = process.env.REACT_APP_STAGE_ENV;
                  switch (env) {
                    case "dev":
                      Api_URL =
                        "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
                      break;
                    case "stage":
                      Api_URL =
                        "https://stage.login.ticca.com/?domain=stage.www.ticca.ltd";
                      break;
                    case "prod":
                      Api_URL = "https://login.ticca.com/?domain=www.ticca.ltd";
                      break;
                    default:
                      Api_URL =
                        "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
                      break;
                  }
                  window.location.href = Api_URL;
                }}
                className="btn-ticca-nav"
                type="submit"
              >
                Log in
              </Button>
            </li>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="navBar-main-container nav-default"
        style={{
          backgroundColor: `${colorChange && !ppm ? "transparent" : "#01014A"}`,
        }}
      >
        {NavBarComm()}
      </div>

      {menuTogle && (
        <>
          <div
            onClick={() => setmenuTogle(false)}
            className="side-menubar"
            style={{ width: "100%", backgroundColor: "transparent" }}
          >
            <div
              className={`side-menubar ${!menuTogle ? "menu-animation" : ""}`}
            >
              <div
                onClick={() => {
                  setmenuTogle(false);
                }}
                className="cross-div"
              >
                <img
                  src={"https://public.ticca.com/images/svg/Close.svg"}
                  alt="crossIcon"
                />
              </div>
              <div className="sidebar-menuList">
                <ul>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.ticcaProduct);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.ticcaProduct
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      What
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.WorkFromAnywhere);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname ==
                          PageRoutes.WorkFromAnywhere
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      Where
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.innovation);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.innovation
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      How
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.aboutUs);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.aboutUs
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      Who
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.WhyChooseUs);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.WhyChooseUs
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      {" "}
                      Why
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.When);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.When
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      {" "}
                      When
                    </li>
                  </div>
                  <div
                    className="sidebar-menuList-dev  investor-drop"
                    style={{
                      position: "relative",
                    }}
                    onClick={() => {
                      setmenuTogle(false);
                      navigation(PageRoutes.InvestorCenter);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.InvestorCenter
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      Investor{" "}
                      {/* <samp>
                      {" "}
                      <img
                        className="solution-downArrow"
                        style={{
                          transform: `${
                            sideinvestorMenu ? "rotate(0deg)" : "rotate(180deg)"
                          }`,
                          position: "relative",
                        }}
                        src={"https://public.ticca.com/images/svg/ArrowUpBlack.svg"}
                      />
                    </samp> */}
                    </li>
                  </div>
                  {userLogin && (
                    <div className="investor-relations-drop-down">
                      <div
                        onClick={() => {
                          if (userLogin) {
                            navigation(PageRoutes.Intro);
                            setmenuTogle(false);
                          }
                        }}
                        style={{
                          backgroundColor: `${
                            window.location.pathname == PageRoutes.Intro
                              ? "rgba(255, 245, 233, 1)"
                              : "transparent"
                          }`,
                        }}
                        className="investor-relations-drop-div"
                      >
                        <p>Intro</p>
                      </div>
                      <div
                        onClick={() => {
                          if (userLogin && userShowPPM) {
                            navigation(PageRoutes.ExecutiveSummary);
                            setmenuTogle(false);
                          } else if (userLogin && !userShowPPM) {
                            requestPPM();
                            setmenuTogle(false);
                          } else if (!userLogin) {
                            setPpmLock(true);
                          } else {
                            return;
                          }
                        }}
                        style={{
                          backgroundColor: `${
                            window.location.pathname ==
                            PageRoutes.ExecutiveSummary
                              ? "rgba(255, 245, 233, 1)"
                              : "transparent"
                          }`,
                        }}
                        className="investor-relations-drop-div"
                      >
                        <p
                          style={{
                            color: `${
                              userShowPPM
                                ? "rgba(92, 92, 92, 1)"
                                : "rgba(209, 209, 209, 1)"
                            }`,
                            position: "relative",
                          }}
                        >
                          <b>Business Summary</b>{" "}
                        </p>
                        {!userShowPPM && busAccessStatus == "key" ? (
                          <samp className="smap-lock">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.1663 8.0013V6.33463C14.1663 4.0013 12.333 2.16797 9.99967 2.16797C7.66634 2.16797 5.83301 4.0013 5.83301 6.33463V8.0013C4.41634 8.0013 3.33301 9.08463 3.33301 10.5013V16.3346C3.33301 17.7513 4.41634 18.8346 5.83301 18.8346H14.1663C15.583 18.8346 16.6663 17.7513 16.6663 16.3346V10.5013C16.6663 9.08463 15.583 8.0013 14.1663 8.0013ZM7.49967 6.33463C7.49967 4.91797 8.58301 3.83464 9.99967 3.83464C11.4163 3.83464 12.4997 4.91797 12.4997 6.33463V8.0013H7.49967V6.33463ZM10.833 14.668C10.833 15.168 10.4997 15.5013 9.99967 15.5013C9.49967 15.5013 9.16634 15.168 9.16634 14.668V12.168C9.16634 11.668 9.49967 11.3346 9.99967 11.3346C10.4997 11.3346 10.833 11.668 10.833 12.168V14.668Z"
                                fill="#5C5C5C"
                              />
                            </svg>
                          </samp>
                        ) : busAccessStatus == "accepted" ? (
                          <samp
                            style={{ color: "rgba(74, 172, 0, 1)" }}
                            className="smap-lock"
                          >
                            accepted
                          </samp>
                        ) : (
                          <samp
                            style={{ color: "rgba(237, 2, 22, 1)" }}
                            className="smap-lock"
                          >
                            pending
                          </samp>
                        )}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p> Shareholders Agreement</p>
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Share Holding Forms,</p>
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Corporate Governance Policy</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Antifraud Policy</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Investor Relations Code of Conduct</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Insider Trading Policy</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Risk Management Policy</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>Whistleblower Policy</p>{" "}
                      </div>
                      <div className="investor-relations-drop-div">
                        <p>2024</p>{" "}
                      </div>

                      <div
                        onClick={() =>
                          navigation(`${PageRoutes.InvestorCenter}#faq`)
                        }
                        className="investor-relations-drop-div"
                      >
                        {" "}
                        <p>FAQ </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="sidebar-menuList-dev"
                    onClick={() => {
                      navigation(PageRoutes.ContactUs);
                    }}
                  >
                    <li
                      style={{
                        backgroundColor: `${
                          window.location.pathname == PageRoutes.ContactUs
                            ? "rgba(229, 240, 252, 1)"
                            : "transparent"
                        }`,
                      }}
                    >
                      ContactUs
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}

      {investorMenu && userLogin && (
        <div
          onMouseLeave={() => {
            setInvestoeMenu(false);
          }}
          id="style-2"
          className="investor-relations-dropdown"
        >
          <div className="investor-relations-lisit">
            <p>Pitch Deck</p>
            <ul>
              <li
                onClick={() => {
                  if (userLogin) {
                    navigation(PageRoutes.Intro);
                  }
                }}
              >
                Intro{" "}
              </li>
              <li
                onClick={() => {
                  navigation(`${PageRoutes.InvestorCenter}#faq`);
                }}
              >
                FAQ{" "}
              </li>
              <li
                style={{
                  position: "relative",
                }}
                onClick={() => {
                  if (userLogin && userShowPPM) {
                    navigation(PageRoutes.ExecutiveSummary);
                  } else if (userLogin && !userShowPPM) {
                    requestPPM();
                  } else if (!userLogin) {
                    // setPpmLock(true);
                  } else {
                    return;
                  }
                }}
              >
                Business Summary{" "}
                {!userShowPPM && busAccessStatus == "key" ? (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "5px",
                    }}
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1673 8.0013V6.33463C14.1673 4.0013 12.334 2.16797 10.0007 2.16797C7.66732 2.16797 5.83398 4.0013 5.83398 6.33463V8.0013C4.41732 8.0013 3.33398 9.08463 3.33398 10.5013V16.3346C3.33398 17.7513 4.41732 18.8346 5.83398 18.8346H14.1673C15.584 18.8346 16.6673 17.7513 16.6673 16.3346V10.5013C16.6673 9.08463 15.584 8.0013 14.1673 8.0013ZM7.50065 6.33463C7.50065 4.91797 8.58398 3.83464 10.0007 3.83464C11.4173 3.83464 12.5007 4.91797 12.5007 6.33463V8.0013H7.50065V6.33463ZM10.834 14.668C10.834 15.168 10.5007 15.5013 10.0007 15.5013C9.50065 15.5013 9.16732 15.168 9.16732 14.668V12.168C9.16732 11.668 9.50065 11.3346 10.0007 11.3346C10.5007 11.3346 10.834 11.668 10.834 12.168V14.668Z"
                        fill="#D1D1D1"
                      />
                    </svg>
                  </samp>
                ) : busAccessStatus == "accepted" ? (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "8px",
                      color: "rgba(74, 172, 0, 1)",
                    }}
                  >
                    accepted
                  </samp>
                ) : (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "8px",
                      color: " rgba(237, 2, 22, 1)",
                    }}
                  >
                    pending
                  </samp>
                )}
              </li>
              <li
                style={{
                  position: "relative",
                }}
                onClick={() => {
                  if (userLogin && userShowPPM) {
                    navigation(PageRoutes.qa);
                  } else if (userLogin && !userShowPPM) {
                    requestPPM();
                  } else if (!userLogin) {
                    // setPpmLock(true);
                  } else {
                    return;
                  }
                }}
              >
                Q&A{" "}
                {!userShowPPM && busAccessStatus == "key" ? (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "5px",
                    }}
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1673 8.0013V6.33463C14.1673 4.0013 12.334 2.16797 10.0007 2.16797C7.66732 2.16797 5.83398 4.0013 5.83398 6.33463V8.0013C4.41732 8.0013 3.33398 9.08463 3.33398 10.5013V16.3346C3.33398 17.7513 4.41732 18.8346 5.83398 18.8346H14.1673C15.584 18.8346 16.6673 17.7513 16.6673 16.3346V10.5013C16.6673 9.08463 15.584 8.0013 14.1673 8.0013ZM7.50065 6.33463C7.50065 4.91797 8.58398 3.83464 10.0007 3.83464C11.4173 3.83464 12.5007 4.91797 12.5007 6.33463V8.0013H7.50065V6.33463ZM10.834 14.668C10.834 15.168 10.5007 15.5013 10.0007 15.5013C9.50065 15.5013 9.16732 15.168 9.16732 14.668V12.168C9.16732 11.668 9.50065 11.3346 10.0007 11.3346C10.5007 11.3346 10.834 11.668 10.834 12.168V14.668Z"
                        fill="#D1D1D1"
                      />
                    </svg>
                  </samp>
                ) : busAccessStatus == "accepted" ? (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "8px",
                      color: "rgba(74, 172, 0, 1)",
                    }}
                  >
                    accepted
                  </samp>
                ) : (
                  <samp
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "8px",
                      color: " rgba(237, 2, 22, 1)",
                    }}
                  >
                    pending
                  </samp>
                )}
              </li>
            </ul>
            <hr />
            <p>Corporate Governance</p>
            <ul>
              <li>Corporate Governance Policy</li>
              <li>Antifraud Policy</li>
              <li>Code of Conduct</li>
              <li>Insider Trading Policy</li>
              <li>Risk Management Policy</li>
              <li>Whistleblower Policy</li>
            </ul>
            <hr />
            <p>Financial Information</p>
            <ul>
              <li>FY 2025 Projections</li>
           
            </ul>
            <hr />
            <p>Shareholder's Document</p>
            <ul>
              {/* <li
                onClick={() => {
                  if (userLogin && userShowPPM) {
                  
                    navigation(PageRoutes.TableContent);
                  } else if (userLogin && !userShowPPM) {
                    requestPPM();
                  }  else if (!userLogin) {
                    setPpmLock(true);
                  } else {
                    return;
                  }
                }}
              >
                PPM
              </li> */}
              <li>Shareholder Agreement  <samp
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "5px",
                  }}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1673 8.0013V6.33463C14.1673 4.0013 12.334 2.16797 10.0007 2.16797C7.66732 2.16797 5.83398 4.0013 5.83398 6.33463V8.0013C4.41732 8.0013 3.33398 9.08463 3.33398 10.5013V16.3346C3.33398 17.7513 4.41732 18.8346 5.83398 18.8346H14.1673C15.584 18.8346 16.6673 17.7513 16.6673 16.3346V10.5013C16.6673 9.08463 15.584 8.0013 14.1673 8.0013ZM7.50065 6.33463C7.50065 4.91797 8.58398 3.83464 10.0007 3.83464C11.4173 3.83464 12.5007 4.91797 12.5007 6.33463V8.0013H7.50065V6.33463ZM10.834 14.668C10.834 15.168 10.5007 15.5013 10.0007 15.5013C9.50065 15.5013 9.16732 15.168 9.16732 14.668V12.168C9.16732 11.668 9.50065 11.3346 10.0007 11.3346C10.5007 11.3346 10.834 11.668 10.834 12.168V14.668Z"
                      fill="#D1D1D1"
                    />
                  </svg>
                </samp></li>
              <li style={{position:"relative"}}>Share Subscription Form  <samp
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "5px",
                  }}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.1673 8.0013V6.33463C14.1673 4.0013 12.334 2.16797 10.0007 2.16797C7.66732 2.16797 5.83398 4.0013 5.83398 6.33463V8.0013C4.41732 8.0013 3.33398 9.08463 3.33398 10.5013V16.3346C3.33398 17.7513 4.41732 18.8346 5.83398 18.8346H14.1673C15.584 18.8346 16.6673 17.7513 16.6673 16.3346V10.5013C16.6673 9.08463 15.584 8.0013 14.1673 8.0013ZM7.50065 6.33463C7.50065 4.91797 8.58398 3.83464 10.0007 3.83464C11.4173 3.83464 12.5007 4.91797 12.5007 6.33463V8.0013H7.50065V6.33463ZM10.834 14.668C10.834 15.168 10.5007 15.5013 10.0007 15.5013C9.50065 15.5013 9.16732 15.168 9.16732 14.668V12.168C9.16732 11.668 9.50065 11.3346 10.0007 11.3346C10.5007 11.3346 10.834 11.668 10.834 12.168V14.668Z"
                      fill="#D1D1D1"
                    />
                  </svg>
                </samp></li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default NavBar;
