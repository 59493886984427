export interface tosttypes {
  position: string | undefined;
  autoClose: number;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress: any;
  theme: string;
  fontFamily: any;
  style: any;
}

export const customToastStyle = {
  fontSize: "14px", // Change the font size as desired
  fontFamily: "var(--Montserrat-Regular)", // Change the font family as desired
};
export const toastCommonestyle = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {
    fontSize: "14px", // Change the font size as desired
    fontFamily: "var(--Montserrat-Regular)", // Change the font family as desired
  },
};
