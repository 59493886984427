import "./popMenu.scss";
const Pending = ({ setpendingPop }: any) => {
  return (
    <>
      <div className="business-summary-popUp">
        <div className="business-summary-main-dev ppm-dev">
          <samp>
            <svg
              width="134"
              height="129"
              viewBox="0 0 134 129"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6085_26749)">
                <path
                  d="M79 110C109.376 110 134 85.3757 134 55C134 24.6243 109.376 0 79 0C48.6243 0 24 24.6243 24 55C24 85.3757 48.6243 110 79 110Z"
                  fill="url(#paint0_linear_6085_26749)"
                />
                <g clip-path="url(#clip1_6085_26749)">
                  <path
                    d="M78.9998 14C101.644 14 119.999 32.3545 119.999 54.9988C119.999 77.6431 101.644 96 78.9998 96C56.3555 96 38.001 77.6428 38.001 54.9988C38.001 32.3548 56.3558 14 78.9998 14Z"
                    fill="#EBEBEB"
                  />
                  <path
                    d="M95.8822 53.7977H80.5077V35.8554C80.5077 34.4385 79.3599 33.293 77.9453 33.293C76.5307 33.293 75.3828 34.4385 75.3828 35.8554V56.3548C75.3828 57.7718 76.5307 58.9173 77.9453 58.9173H95.8822C97.2992 58.9173 98.4447 57.7718 98.4447 56.3548C98.4447 54.9455 97.2992 53.7977 95.8822 53.7977Z"
                    fill="#1959DB"
                  />
                  <path
                    d="M78.9998 14C56.3558 14 38.001 32.3545 38.001 54.9988C38.001 77.6431 56.3555 96 78.9998 96C101.644 96 119.999 77.6428 119.999 54.9988C119.999 32.3548 101.644 14 78.9998 14ZM78.9998 88.3129C60.6324 88.3129 45.6884 73.3689 45.6884 54.9988C45.6884 36.6314 60.6324 21.6874 78.9998 21.6874C97.3672 21.6874 112.311 36.6314 112.311 54.9988C112.311 73.3689 97.3672 88.3129 78.9998 88.3129Z"
                    fill="#063490"
                  />
                </g>
                <path
                  d="M0 105.12L36.4035 71L37.8996 73.0365L0 105.12ZM40.6272 129L73.7826 92.5363L76 93.5537L40.6272 129ZM14.0687 125.422L43.2137 97.4905L44.8001 98.8779L14.0679 125.422H14.0684L14.0687 125.422Z"
                  fill="#ECF0F1"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_6085_26749"
                  x1="79"
                  y1="0"
                  x2="79"
                  y2="110"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#57E4FF" />
                  <stop offset="1" stop-color="#0071ED" />
                </linearGradient>
                <clipPath id="clip0_6085_26749">
                  <rect width="134" height="129" fill="white" />
                </clipPath>
                <clipPath id="clip1_6085_26749">
                  <rect
                    width="82"
                    height="82"
                    fill="white"
                    transform="translate(38 14)"
                  />
                </clipPath>
              </defs>
            </svg>
          </samp>
          <p style={{ color: "white" }}>
            Thank you for your request.
            <br />
            It is currently <b>pending</b> review by our Founder and will be
            addressed as soon as possible.
          </p>

          <samp
            onClick={() => {
              setpendingPop(false);
            }}
            className="investor-close"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="rgba(241, 246, 255, 1)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 23L9 9M23 9L9 23"
                stroke="rgba(241, 246, 255, 1)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </samp>
        </div>
      </div>
    </>
  );
};

export default Pending;
