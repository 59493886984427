import axios, { AxiosResponse } from "axios";
import { jsonToFrom } from "../JsonToForm";
import { loginToken } from "../../components/Navbar/Navbar";

let Api_URL: any = "";
const env = process.env.REACT_APP_STAGE_ENV;

switch (env) {
  case "dev":
    Api_URL = process.env.REACT_APP_TICCA_API_DEV_ENDPOINT;
    break;
  case "stage":
    Api_URL = process.env.REACT_APP_TICCA_API_STAGE_ENDPOINT;
    break;
  case "prod":
    Api_URL = process.env.REACT_APP_TICCA_API_ENDPOINT;
    break;
  default:
    Api_URL = process.env.REACT_APP_TICCA_API_DEV_ENDPOINT;
    break;
}

export const API = axios.create({ baseURL: `${Api_URL && Api_URL}` });
API.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
const token = JSON.parse(localStorage.getItem("access_token") as any);
export interface PPM {
  post_id: string;
  ip_address: string;
  form_lat: string;
  form_lon: string;
}
export interface requestPPM {
  ip_address: string;
  latitude: string;
  longitude: string;
  contact_uid?: string;
}
export interface SurveyQuestion {
  [question: string]: string; 
}

export interface SurveyData {
  ip_address: string;
  latitude: string;
  longitude: string;
  survey: any; 
}
const objectToFormData = (obj: any, form?: FormData, namespace?: string): FormData => {
  let formData = form || new FormData();

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property;

      // If the value is an object (not a File), recurse to handle nested objects
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], formData, formKey);
      } else {
        // Append the key-value pair
        formData.append(formKey, obj[property]);
      }
    }
  }

  return formData;
};



export const fetIpAddress = () => axios.get(`https://ip.ticca.com/`);

export const LoginTokenVerifty = (data: loginToken) =>
  API.post("/auth/validate-token", jsonToFrom(data));
export const getUserProfile = (data: any) =>
  API.post("/kaos/get/profile ", jsonToFrom(data), {
    headers: {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("access_token") as any
      )}`,
    },
  });



export const GetcmsContent = (data: PPM) =>
  API.post("/cms-management/private-content", jsonToFrom(data), {
    headers: {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("access_token") as any
      )}`,
    },
  });
export const GetPubliccmsContent = (data: PPM) =>
  API.post("/cms-management/public-content", jsonToFrom(data));

export const GetLogout = (data: any) =>
  API.post(`/kaos/logout`, jsonToFrom(data), {
    headers: { Authorization: `Bearer ${token}` },
  });
export const BusinessSummaryAccessRequest = (data: requestPPM) =>
  API.post("/control/request/exec-summary", jsonToFrom(data), {
    headers: {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("access_token") as any
      )}`,
    },
  });

export const AccessBusinessSummary = (data: requestPPM) =>
  API.post("/control/access/exec-summary", jsonToFrom(data), {
    headers: {
      Authorization: `Bearer ${JSON.parse(
        localStorage.getItem("access_token") as any
      )}`,
    },
  });
  export const PostTalkToOurFounder = (data :any  ) =>
    API.post("/control/request/talk-to-our-founder", data, {
      headers: {
        Authorization: `Bearer ${JSON.parse(
          localStorage.getItem("access_token") as any
        )}`,
      },
    });

    export const GetTalkToOurFounder = (data :requestPPM  ) =>
      API.post("/control/get/talk-to-our-founder", jsonToFrom(data), {
        headers: {
          Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("access_token") as any
          )}`,
        },
      });
   
