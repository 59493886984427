import React, { createContext, useContext, useState } from "react";

interface ContextType {
  userLogin: boolean;
  setUserLogin: (data: boolean) => void;
  userShowPPM: boolean;
  setUserShowPPM: (data: boolean) => void;
  busAccessStatus:string;
  setBusAccessStatus : (data : string) => void
}

const defaultState = {
  userLogin: false,
  setUserLogin: () => {},
  userShowPPM: false,
  setUserShowPPM: () => {},
  busAccessStatus:"key",
  setBusAccessStatus : ()=> {}
} as ContextType;
export const Context = createContext(defaultState);

// Define a custom hook to use the context
const useToggle = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useToggle must be used within a ToggleProvider");
  }
  return context;
};

// Create a provider component to wrap around your app
const ContextProvider = ({ children }: any) => {


  const [userLogin, setUserLogin] = useState(false);
  const [userShowPPM, setUserShowPPM] = useState(false);
  const [busAccessStatus, setBusAccessStatus] = useState("key");
  const contextValue: ContextType = {
    userLogin,
    setUserLogin,
    userShowPPM,
    setUserShowPPM,
    busAccessStatus,
    setBusAccessStatus
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { ContextProvider, useToggle };
