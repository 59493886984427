import NavBar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/footer";
import { PageRoutes } from "./utils/pageRoutes";

function PageRoute({ children }: any) {
  return (
    <>
      <div>
        <NavBar />
        {children}
        {window.location.pathname !== PageRoutes.ExecutiveSummary &&
          window.location.pathname !== PageRoutes.Intro &&
          window.location.pathname !== PageRoutes.question && <Footer />}
      </div>
    </>
  );
}

export default PageRoute;
